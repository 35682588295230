import React from "react";
import styles from "./ProductImageDisplay.module.scss";

export interface ProductImageDisplayProps {
	image: string;
	size?: ProductImageDisplaySize;
}

export enum ProductImageDisplaySize {
	Standard,
	Large
}

export const ProductImageDisplay = ({ image, size = ProductImageDisplaySize.Standard }: ProductImageDisplayProps): JSX.Element => {
	const classNames = [ styles.productImageDisplay ];
	if (size === ProductImageDisplaySize.Large) {
		classNames.push(styles.large);
	}

	return (
		<div className={classNames.join(" ")}>
			<img src={image} />
		</div>
	);
};
