import { LegacyScriptTagConfiguration } from "./LegacyScriptTagConfiguration";

export interface GetMerchantResponseModel {
	readonly name: string;
	readonly domain: string;
	readonly slug: string;
	readonly klaviyoConfigured: boolean;
	readonly logoUrl: string | null;
	readonly openAffiliateReferralImageUrl: string | null;
	readonly brandImageUrl: string | null;
	readonly scriptTagConfiguration: LegacyScriptTagConfiguration;
	readonly allowCustomerCommunications: boolean;
	readonly checkoutType: CheckoutType;
	readonly checkoutExtensionsEnabled: boolean;
	readonly influencerDiscoveryDeliveryType: InfluencerDiscoveryType;
}

export enum InfluencerDiscoveryType {
	Slack= 1,
	Email= 2,
}

export interface CheckoutType {
	Scripts: "Scripts";
	Extensions: "Extensions";
}
