import  React from "react";

export interface RadioButtonGroupProps {
	options: string[];
	selectedOption: string;
	onChange: (option: string) => void;
}

export const RadioButtonGroup = ({ options, selectedOption, onChange }: RadioButtonGroupProps): JSX.Element => {
	return (
		<div>
			{options.map((option) => (
				<label key={option}>
					<input
						type="radio"
						value={option}
						checked={option === selectedOption}
						onChange={() => onChange(option)}
					/>
					{option}
				</label>
			))}
		</div>
	);
};
