import { MutationFunction, useQueryClient } from "react-query";
import { Response, useHTTP, useHTTPMutation } from "shared/lib/http";
import { useUserAuthState } from "src/features/auth/UserAuthState";
import { API_BASE_URL } from "src/lib/config";
import { InfluencerDiscoveryType } from "./models/GetMerchantResponseModel";

export interface SetMerchantInfluencerDiscoveryDeliveryOptions {
	merchantId: string;
	requestModel: SetMerchantInfluencerDiscoveryDeliveryRequestModel;
}

export interface SetMerchantInfluencerDiscoveryDeliveryRequestModel {
	readonly deliveryType: InfluencerDiscoveryType;
}

export const useSetMerchantInfluencerDiscoveryDelivery = () => {
	const queryClient = useQueryClient();
	const { state } = useUserAuthState();
	const http = useHTTP(state.accessToken);

	const mutFunc: MutationFunction<Response<undefined>, SetMerchantInfluencerDiscoveryDeliveryOptions> = (options: SetMerchantInfluencerDiscoveryDeliveryOptions) => {
		return http<undefined, SetMerchantInfluencerDiscoveryDeliveryRequestModel>({
			method: "PUT",
			url: `${API_BASE_URL}/admin/merchant/${options.merchantId}/influencer-discovery-delivery-type`,
			body: options.requestModel
		});
	};

	return useHTTPMutation(mutFunc, {
		onSuccess: (_, options) => {
			queryClient.invalidateQueries(["merchant", options.merchantId]);
		}
	});
};
